@font-face {
	font-family: Futura;
	src: url(./assets/fonts/futura.ttf);
}

@font-face {
	font-family: 'Futura Outline';
	src: url(./assets/fonts/futura-outline.otf);
}

html, .App, body {
	height: 100%;
}

body {
	margin: 0;
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
	min-width: 700px;
}

#root {
	min-height: 100%;
	width: 100%;
}

.brand {
	font-family: Futura;
}

button.link {
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-size: 1em;
	text-align: left;
	color: blue;
	background: none;
	margin: 0;
	padding: 0;
	border: none;
	cursor: pointer;
}

.visuallyhidden {
 display: inline-block;
 position: absolute !important;
 height: 1px; width: 1px; 
 overflow: hidden;
 clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
 clip: rect(1px, 1px, 1px, 1px);
 clip-path: polygon(0px 0px, 0px 0px,0px 0px, 0px 0px);
}

.fade-in {
  animation: fadeIn ease 3s;
  -webkit-animation: fadeIn ease 3s;
  -moz-animation: fadeIn ease 3s;
  -o-animation: fadeIn ease 3s;
  -ms-animation: fadeIn ease 3s;
}

@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}